import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

const IntroAnimation = ({ onComplete }) => {
  const [step, setStep] = useState(0);
  const words = ['truth', 'picks', '-', 'mari', '.com'];

  const props = useSpring({
    opacity: 1,
    transform: 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(40px)' },
    reset: true,
    reverse: step === words.length,
    delay: 5,
    config: config.molasses,
    onRest: () => {
      if (step < words.length) {
        setStep(step + 1);
      } else if (step === words.length) {
        onComplete();
      }
    },
  });

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'black',
      zIndex: 9999,
    }}>
      <animated.div style={props}>
        <h1 style={{ color: 'white', fontSize: '3rem', fontWeight: 'bold' }}>
          {words.slice(0, step).join('')}
        </h1>
      </animated.div>
    </div>
  );
};

export default IntroAnimation;