import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { Search, Grid, Activity, Trophy, ChevronDown, X, TrendingUp } from 'lucide-react';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import IntroAnimation from './IntroAnimation';

// Dummy data for predictions
const dummyPredictions = [
  {
    title: "Presidential Election Winner 2024",
    options: [
      { name: "Kamala Harris", percentage: 51, type: "Yes" },
      { name: "Donald Trump", percentage: 49, type: "No" },
    ],
    volume: "1.1b",
    comments: 99161,
    stars: 57
  },
  {
    title: "U.S. Recession in 2024?",
    options: [
      { name: "Yes", percentage: 35, type: "Yes" },
      { name: "No", percentage: 65, type: "No" },
    ],
    volume: "500m",
    comments: 45230,
    stars: 43
  },
  {
    title: "Diddy to be convicted in 2025",
    options: [
      { name: "Yes", percentage: 35, type: "Yes" },
      { name: "No", percentage: 65, type: "No" },
    ],
    volume: "500m",
    comments: 45230,
    stars: 43
  },
  {
    title: "Bitcoin to hit $100k by 2026",
    options: [
      { name: "Yes", percentage: 45, type: "Yes" },
      { name: "No", percentage: 55, type: "No" }
    ],
    volume: "500m",
    comments: 48520,
    stars: 50
  },
  {
    title: "Elon Musk to step down as Tesla CEO by 2024",
    options: [
      { name: "Yes", percentage: 55, type: "Yes" },
      { name: "No", percentage: 45, type: "No" }
    ],
    volume: "600m",
    comments: 60500,
    stars: 60
  },
  {
    title: "Mars manned mission to launch by 2030",
    options: [
      { name: "Yes", percentage: 35, type: "Yes" },
      { name: "No", percentage: 65, type: "No" }
    ],
    volume: "400m",
    comments: 48200,
    stars: 47
  }
  // Add more dummy predictions here if needed
];

const AnimatedCard = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false);
  const springProps = useSpring({
    scale: isHovered ? 1.05 : 1,
    boxShadow: isHovered
      ? '0 10px 30px -5px rgba(0, 0, 0, 0.3)'
      : '0 5px 15px -5px rgba(0, 0, 0, 0.1)',
    config: config.wobbly,
  });

  return (
    <animated.div
      style={springProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="bg-gray-800 rounded-lg overflow-hidden"
    >
      {children}
    </animated.div>
  );
};

const MarketCard = ({ title, options, volume, comments, stars }) => (
  <AnimatedCard>
    <Card className="bg-gray-800 text-white border-none">
      <CardHeader>
        <CardTitle className="text-lg font-semibold">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        {options.map((option, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <span className="text-gray-300">{option.name}</span>
            <span className="font-bold text-yellow-400">{option.percentage}%</span>
            <Button size="sm" variant={option.type === 'Yes' ? 'success' : 'destructive'} className="px-4">
              {option.type}
            </Button>
          </div>
        ))}
        <div className="flex justify-between text-xs text-gray-400 mt-4">
          <span>${volume} Vol.</span>
          <span>{comments} <i className="fas fa-comment"></i></span>
          <span>{stars} <i className="fas fa-star"></i></span>
        </div>
      </CardContent>
    </Card>
  </AnimatedCard>
);

const AnimatedButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const springProps = useSpring({
    scale: isHovered ? 1.05 : 1,
    config: config.wobbly,
  });

  return (
    <animated.div
      style={springProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button {...props}>{children}</Button>
    </animated.div>
  );
};

const FloatingActionButton = () => {
  const [{ y }, set] = useSpring(() => ({ y: 0 }));
  const bind = useDrag(({ down, movement: [, my] }) => {
    set({ y: down ? my : 0, immediate: down });
  });

  return (
    <animated.div
      {...bind()}
      style={{
        y,
        position: 'fixed',
        bottom: 20,
        right: 20,
        touchAction: 'none',
      }}
    >
      <Button className="w-12 h-12 rounded-full bg-yellow-400 hover:bg-yellow-500 text-gray-900">
        <ChevronDown size={24} />
      </Button>
    </animated.div>
  );
};

const Modal = ({ isOpen, onClose, children }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateY(0%)' : 'translateY(-100%)',
  });

  if (!isOpen) return null;

  return (
    <animated.div style={animation} className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl relative">
        <Button onClick={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-white">
          <X size={24} />
        </Button>
        {children}
      </div>
    </animated.div>
  );
};

function App() {
  const [visiblePredictions, setVisiblePredictions] = useState(4);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showIntro, setShowIntro] = useState(() => {
    return !localStorage.getItem('introShown');
  });

  const headerAnimation = useSpring({
    opacity: showIntro ? 0 : 1,
    transform: showIntro ? 'translateY(-50px)' : 'translateY(0)',
    config: config.molasses,
  });

  const fadeIn = useSpring({
    opacity: showIntro ? 0 : 1,
    config: config.molasses,
  });

  const cardAnimation = useSpring({
    opacity: showIntro ? 0 : 1,
    transform: showIntro ? 'translateY(50px)' : 'translateY(0)',
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      setVisiblePredictions(prev => Math.min(prev + 2, dummyPredictions.length));
    };

    if (!showIntro) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, [showIntro]);

  const handleIntroComplete = () => {
    setShowIntro(false);
    localStorage.setItem('introShown', 'true');
  };

  if (showIntro) {
    return <IntroAnimation onComplete={handleIntroComplete} />;
  }


  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <animated.header style={headerAnimation} className="bg-gray-800 p-4 sticky top-0 z-10">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <h1 className="text-2xl font-bold text-yellow-400">truthpicks</h1>
            <div className="relative">
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <Input
                type="text"
                placeholder="Search markets"
                className="pl-10 bg-gray-700 border-gray-600 text-white placeholder-gray-400 w-64"
              />
            </div>
          </div>
          <nav className="flex items-center space-x-4">
            <AnimatedButton variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-700">
              <Grid size={20} />
            </AnimatedButton>
            <AnimatedButton variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-700">
              <Activity size={20} />
            </AnimatedButton>
            <AnimatedButton variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-700">
              <Trophy size={20} />
            </AnimatedButton>
            <AnimatedButton variant="outline" className="text-white border-yellow-400 hover:bg-yellow-400 hover:text-gray-900 transition-colors duration-300">
              Log In
            </AnimatedButton>
            <AnimatedButton className="bg-yellow-400 hover:bg-yellow-500 text-gray-900 transition-colors duration-300" onClick={() => setIsModalOpen(true)}>
              Sign Up
            </AnimatedButton>
          </nav>
        </div>
      </animated.header>

      <main className="container mx-auto p-4">
        <animated.section style={fadeIn} className="mb-12 text-center py-16">
          <h2 className="text-4xl font-bold mb-4">Predict the Future, Profit from Knowledge</h2>
          <p className="text-xl text-gray-300 mb-8">Join Truthpicks, the premier prediction market for politics, sports, and global events.</p>
          <AnimatedButton size="lg" className="bg-yellow-400 hover:bg-yellow-500 text-gray-900 text-lg px-8 py-3">
            Start Predicting Now
          </AnimatedButton>
        </animated.section>

        <animated.section style={fadeIn} className="mb-12">
          <h3 className="text-2xl font-semibold mb-6">Featured Markets</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <AnimatedCard>
              <Card className="bg-gradient-to-r from-blue-600 to-purple-600 border-none">
                <CardContent className="flex justify-between items-center p-6">
                  <div>
                    <h3 className="font-bold text-xl mb-2">2024 Election Forecast</h3>
                    <AnimatedButton variant="secondary" size="sm" className="bg-white text-gray-900 hover:bg-gray-200 transition-colors duration-300">
                      View
                    </AnimatedButton>
                  </div>
                  <TrendingUp size={48} />
                </CardContent>
              </Card>
            </AnimatedCard>
            {/* Add two more featured market cards here */}
          </div>
        </animated.section>

        <animated.section style={fadeIn} className="mb-8">
          <div className="flex space-x-2 overflow-x-auto pb-2">
            <AnimatedButton variant="default" className="bg-yellow-400 hover:bg-yellow-500 text-gray-900 transition-colors duration-300">
              Top
            </AnimatedButton>
            <AnimatedButton variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-700">
              New
            </AnimatedButton>
            <AnimatedButton variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-700">
              Breaking News
            </AnimatedButton>
            <AnimatedButton variant="ghost" className="text-gray-300 hover:text-white hover:bg-gray-700">
              US Election
            </AnimatedButton>
          </div>
        </animated.section>

        <animated.section style={fadeIn}>
          <h3 className="text-2xl font-semibold mb-6">Popular Predictions</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {dummyPredictions.slice(0, visiblePredictions).map((prediction, index) => (
              <animated.div key={index} style={{...cardAnimation, delay: index * 100}}>
                <MarketCard {...prediction} />
              </animated.div>
            ))}
          </div>
        </animated.section>

        {visiblePredictions < dummyPredictions.length && (
          <div className="text-center mt-8">
            <AnimatedButton 
              variant="outline" 
              size="lg" 
              className="text-yellow-400 border-yellow-400 hover:bg-yellow-400 hover:text-gray-900 transition-colors duration-300"
              onClick={() => setVisiblePredictions(prev => Math.min(prev + 3, dummyPredictions.length))}
            >
              Load More
            </AnimatedButton>
          </div>
        )}
      </main>

      <footer className="bg-gray-800 text-gray-300 py-12 mt-16">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h4 className="text-lg font-semibold mb-4">About Truthpicks</h4>
            <p>@mari this is live fr, haha!</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><a href="#" className="hover:text-yellow-400">How It Works</a></li>
              <li><a href="#" className="hover:text-yellow-400">FAQ</a></li>
              <li><a href="#" className="hover:text-yellow-400">Terms of Service</a></li>
              <li><a href="#" className="hover:text-yellow-400">Privacy Policy</a></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <p>Email: support@truthpicks.com</p>
            <p>Phone: (555) 123-4567</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
            <div className="flex space-x-4">
              <a href="#" className="text-2xl hover:text-yellow-400"><i className="fab fa-twitter"></i></a>
              <a href="#" className="text-2xl hover:text-yellow-400"><i className="fab fa-facebook"></i></a>
              <a href="#" className="text-2xl hover:text-yellow-400"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
      </footer>

      <FloatingActionButton />

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Sign Up for Truthpicks</h2>
        <p className="mb-4">Join our community of predictors and start making your own forecasts!</p>
        <Input className="mb-4" placeholder="Email" />
        <Input className="mb-4" type="password" placeholder="Password" />
        <AnimatedButton className="w-full bg-yellow-400 hover:bg-yellow-500 text-gray-900">
          Create Account
        </AnimatedButton>
      </Modal>
    </div>
  );
}

export default App;